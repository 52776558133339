import React from 'react';
import PropTypes from 'prop-types';

import icomoon from '../icomoon.json';

export const Icon = ({ name, className, fill }) => {
  const icon = icomoon.icons.find(icon => icon.properties.name === name);
  if (icon) {
    return (
      <svg className={`icon ${className}`} width="100%" height="100%" viewBox="0 0 1024 1024">
        <title>{name}</title>
        <path d={icon.icon.paths.join(' ')} fill={!fill && icon.attrs.length === 1 ? icon.attrs[0].fill : fill} />
      </svg>
    );
  } else {
    console.log('Icon not found for', name);
    return '';
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};