import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, Link, graphql } from 'gatsby';

import { Icon } from './Icon';
import '../bulma-base.sass';
import './layout.css';
import logo from '../images/logo.png';

const toggleNavMenu = ({ target }) => {
  // Get the target from the "data-target" attribute
  const $target = document.getElementById(target.dataset.target);
  if (!$target) return;
  // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
  target.classList.toggle('is-active');
  $target.classList.toggle('is-active');
}

export const Layout = ({ children, title, navbar, description, pageClass }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            tagline
            description
            email
            social {
              twitter
              linkedin
              github
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <>
        <Helmet
          title={title ? `${title} - ${siteMetadata.title}` : `${siteMetadata.title} - ${siteMetadata.tagline}`}
        >
          <html lang="en" />
          <meta name="description" content={description || siteMetadata.description} />
        </Helmet>
        <nav className="navbar is-dark has-shadow" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/" title="Main Page">
                <img alt="Faisal Hasnain Logo" src={logo} height="28" width="28" />&nbsp;&nbsp;<h1 className="title is-5 has-text-light">{siteMetadata.title}</h1>
              </Link>
              <span role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMenu" onClick={toggleNavMenu}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </span>
            </div>
            <div id="navbarMenu" className="navbar-menu">
              <div className="navbar-end">
                <Link className={`navbar-item ${navbar === 'articles' ? 'is-active' : ''}`} to="/#articles">Articles</Link>
                <Link className={`navbar-item ${navbar === 'technologies' ? 'is-active' : ''}`} to="/#technologies">Technologies</Link>
                <Link className={`navbar-item ${navbar === 'services' ? 'is-active' : ''}`} to="/#services">Services</Link>
                <Link className={`navbar-item ${navbar === 'experience' ? 'is-active' : ''}`} to="/#experience">Experience</Link>
                <Link className={`navbar-item ${navbar === 'education' ? 'is-active' : ''}`} to="/#education">Education</Link>
                <Link className={`navbar-item ${navbar === 'about' ? 'is-active' : ''}`} to="/#about">About</Link>
              </div>
            </div>
          </div>
        </nav>
        <div className={pageClass} style={{ minHeight: '75vh' }}>
          {children}
        </div>
        <footer className="footer">
          <div className="container">
            <div className="columns">
              <div className="column is-6">
                <h3 className="subtitle is-5">About {siteMetadata.title}</h3>
                <p className="has-text-justified">
                  {siteMetadata.description}
                </p>
              </div>
              <div className="column is-4 is-offset-2">
                <h3 className="subtitle is-5">Stay Connected</h3>
                <div className="social-icons">
                  <a href={siteMetadata.social.twitter} target="_blank" rel="noopener noreferrer"><Icon name="twitter" /></a>
                  <a href={siteMetadata.social.github} target="_blank" rel="noopener noreferrer"><Icon name="github" /></a>
                  <a href={siteMetadata.social.linkedin} target="_blank" rel="noopener noreferrer"><Icon name="linkedin" /></a>
                  <a href={`mailto:${siteMetadata.email}`} target="_blank" rel="noopener noreferrer"><Icon name="email" /></a>
                </div>
              </div>
            </div>
            <hr />
            <div className="content is-size-7 has-text-centered has-text-grey">
              <p>
                Copyright &copy; {siteMetadata.title}
              </p>
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
